main {
    background-image: url(assets/bkg-1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    width: 100%;
    position: relative;
    height: 100vh;
    max-height: -webkit-fill-available;
    -webkit-user-select: none;
    user-select: none;
}
